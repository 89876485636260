
import { defineComponent } from "vue";
import Lock from "@/assets/svg/Lock.svg?inline";

export default defineComponent({
  name: "Card",
  data() {
    return {};
  },
  props: {
    imgCatalog: {
      type: String,
    },
    attributes: {
      type: Array,
    },
    name: {
      type: String,
    },
  },
  components: {
    Lock,
  },
  mounted() {},
  methods: {},
});
