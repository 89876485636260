
import Lock from "@/assets/svg/Lock.svg?inline";
import MainButton from "@/components/atoms/buttons/MainButton.vue";
import Discord from "@/assets/svg/discord-blue.svg?inline";
import Twitter from "@/assets/svg/twitter-blue.svg?inline";
import Card from "@/components/molecules/dashboard/Card.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CollectionDetailsSection",
  data() {
    return {
      card: "",
      myNFT: null,
    };
  },
  components: {
    Lock,
    MainButton,
    Discord,
    Twitter,
    Card,
  },
  mounted() {
    this.card = localStorage.getItem("my-nft").replace(/["']/g, "");
    this.getNft()
  },
  methods: {
    async getNft() {
    const NFTs = require("@/plugins/solana-nft-tools");
    const web3 = require("@solana/web3.js");
    const conn = new web3.Connection(web3.clusterApiUrl("devnet"), "confirmed");
    this.myNFT = await NFTs.getNFTByMintAddress(conn, this.card);
  },
  },
});
