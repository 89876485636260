
import { defineComponent } from 'vue'
import SecondLayout from '@/templates/SecondLayout.vue'
import CollectionDetailsSection from '@/components/sections/dashboard/CollectionDetailsSection.vue'

export default defineComponent({
  name: 'CollectionDetails',
  components: {
    SecondLayout,
    CollectionDetailsSection
  },
  mounted() {
    const token = localStorage.getItem('phantom-token');
    if(token == null) {
      this.$router.push('/registration-phantom')
    }
  }
})
